body {
  margin: 0;
  padding: 0;
  background-color: #cdecff;
}


/* Date picker fix for calendar */

div.btn-group > div {
    display: inline;
}

div.btn-group div.react-datepicker-wrapper {
    display: inline;
}

div.btn-group div.react-datepicker__input-container {
    display: inline;
}

div.btn-group div.react-datepicker__input-container input {
    max-width: 100px;
}


div.btn-group div.react-datepicker__tab-loop {
  float: left;
}

/* Calendar */

.calendar-header button {
  visibility: hidden;
}

.calendar-header:hover button {
 visibility: visible;
}

.calendar-main-grid>tbody>tr>td:first-child {
  min-width: 126px;
  max-width: 200px;
}

.calendar-main-grid>tbody>tr>td {
  padding: 0;
  margin: 0;
  width: 10%;
}

.hour-table-wrapper {
  position: relative;
}

.hour-table {
  padding: 0;
  margin: 0;
}

.hour-table>tbody>tr>td {
  padding: 0;
  height: 20px;
}

.hour-table.hour-table-times {
  max-width: 60px;
}

.hour-table-hover>td {
    background-color: deepskyblue;
}

.calendar-entry {
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  padding: 1px;
  width:100%;
}

.calendar-entry-type-work {
  background-color: lightblue;
  border-color: skyblue;
}

.calendar-entry-type-survey {
  background-color: moccasin;
  border-color: gold;
}

.calendar-entry-type-annual-leave {
  background-color: lightgreen;
  border-color: mediumspringgreen;
}

.calendar-entry-type-training {
  background-color: lightgrey;
  border-color: grey;
}

.calendar-entry-type-sickness {
  background-color: lightpink;
  border-color: hotpink;
}

.calendar-entry-type-appointment {
  background-color: lightcyan;
  border-color: cyan;
}

.calendar-entry-type-unpaid-leave {
  background-color: lightpink;
  border-color: hotpink;
}

.calendar-entry-type-rebook {
  opacity: .4;
}

.calendar-entry-provisional {
  opacity: .7;
  font-style: italic;
}

/* Loader */

div.loader-overlay {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333;
  opacity: 0.2;
  z-index: 1060;
}

div.loader-overlay > div {
  color: white;
  display: table-cell;
  width: 100%;
  height: 100%;
  opacity: 1;
  text-align: center;
  vertical-align: middle;
  z-index: 1061;
}

/* Job list */

.list-table {
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
}

.list-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-list-table th {
  width: 14%;
}

.job-list-table td {
  cursor: pointer;
}

/* Lists */
.btn-toolbar {
  padding-bottom: 5px;
}

/* Other */
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.modal-footer button {
  min-width: 120px;
}

.dropzone-div {
  text-align: center;
  padding: 20px;
  border: 3px #337ab7 dashed;
  width: 100%;
  margin: auto;
  cursor: pointer;
}
